<template>
	<div>
		<header class="top-nav">
			<nuxt-link to="/" class="logo">PRE-USED EV</nuxt-link>
			<nav>
				<ul>
					<li><nuxt-link to="/inventory">Inventory</nuxt-link></li>
					<li><nuxt-link to="/sell-your-tesla">Sell Your Tesla</nuxt-link></li>
					<li><nuxt-link to="/saved">Saved ({{savedCars}})</nuxt-link></li>
					<client-only>
					<li v-if="isLoggedIn"><nuxt-link to="/alerts" class="btn-primary">Manage Alerts</nuxt-link></li>
					<li v-else><div @click="login()" class="btn-primary">Get Free Alerts</div></li>
					</client-only>
				</ul>
			</nav>
			<client-only><div @click="logout()" v-if="isLoggedIn" class="logout">Logout</div></client-only>
		</header>
	</div>
</template>
<script>

export default {
	data(){
		return {
			// loggedIn: false
			isExpired: false
		}
	},
	components: {
	},
	methods: {
		login(){
			this.$auth.loginWith("awsCognito");
		},
		logout(){
			this.$auth.logout();
		}
	},
	mounted(){
		var tokenStatus = this.$auth.strategy.token.status();
		if(this.$auth.loggedIn && this.$auth.strategy.token.status().expired()){
			this.isExpired = true;
			this.$auth.logout();
			this.$auth.refreshTokens().then(function(){
			});
		}
	},
	computed: {
		isLoggedIn(){
			if(this.isExpired){
				return false;
			}
			return this.$auth.loggedIn;
		},
		savedCars(){
			return this.$store.state.saved.cars.length
		}
	}
}
</script>
<style lang="scss">
	.top-nav {
		background: url('~assets/header-background.jpg') no-repeat;
		background-size: cover;
		height: 140px;
		display: grid;
		grid-template-columns: repeat(2,auto);
		grid-template-columns: 1fr 4fr;
		align-items: center;
		padding: 0px 40px;
		.logo, li a {
			display: block;
			text-transform: uppercase;
			text-decoration: none;
			font-weight: bold;
			color: white;
		}
		.logo{
			text-indent: -9999em;
			width: 336px;
			height: 60px;
			background: url('~assets/logo-w-text@2x.png') no-repeat;
			background-size: cover;
			@media only screen and (max-width: 450px){
				width: 70px;
			}
		}
		.logout{
			text-align: right;
			color: $white;
			font-size: 14px;
			font-weight: 600;
			position: absolute;
			right: 50px;
			top: 10px;
			font-style: italic;
		}
		.btn-primary{
			font-size: 14px;
			padding-left: 26px;
			padding-right: 24px;
			color: $white!important;
			&:hover{
				color: $red!important;
			}
			@media only screen and (max-width: 450px){
				padding-left: 6px;
				padding-right: 4px;
				line-height: 14px;
			}
		}
		nav {
			justify-self: right;
		}
		ul {
			list-style-type: none;
			margin-bottom: 0;
		}
		li {
			display: inline;
		}
		li a {
			padding: 0px 40px 0px 0px;
			font-weight: 600;
			font-size: 18px;
			color: $white;
			display: inline-block;
			&:hover{
				color: $grey;
				text-decoration: none;
			}
			&.nuxt-link-active{
				font-weight: 800;
				color: $grey;
				&:hover{
					color: $white;
				}
			}
		}
	}
</style>
