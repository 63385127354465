import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _36430cce = () => interopDefault(import('../pages/alerts/index.vue' /* webpackChunkName: "pages/alerts/index" */))
const _2f458c1b = () => interopDefault(import('../pages/inventory/index.vue' /* webpackChunkName: "pages/inventory/index" */))
const _40443030 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _04b49986 = () => interopDefault(import('../pages/saved/index.vue' /* webpackChunkName: "pages/saved/index" */))
const _1d271dd2 = () => interopDefault(import('../pages/sell-your-tesla/index.vue' /* webpackChunkName: "pages/sell-your-tesla/index" */))
const _43537084 = () => interopDefault(import('../pages/content/_content.vue' /* webpackChunkName: "pages/content/_content" */))
const _42c9f578 = () => interopDefault(import('../pages/vin/_vin.vue' /* webpackChunkName: "pages/vin/_vin" */))
const _845823e4 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/alerts",
    component: _36430cce,
    name: "alerts"
  }, {
    path: "/inventory",
    component: _2f458c1b,
    name: "inventory"
  }, {
    path: "/login",
    component: _40443030,
    name: "login"
  }, {
    path: "/saved",
    component: _04b49986,
    name: "saved"
  }, {
    path: "/sell-your-tesla",
    component: _1d271dd2,
    name: "sell-your-tesla"
  }, {
    path: "/content/:content?",
    component: _43537084,
    name: "content-content"
  }, {
    path: "/vin/:vin?",
    component: _42c9f578,
    name: "vin-vin"
  }, {
    path: "/",
    component: _845823e4,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
