<template>
	<div class="faded-bar">
		Buying a New Tesla? Use this <a href="https://ts.la/jeffrey51305">Referral Link</a>
	</div>
</template>
<script>
import config from '@/config';
import _ from 'lodash';

export default {
	components: {
	},
	data() {
		return {
		}
	},
	computed: {
	},
	methods: {
	},
	props: [
		'vin'
	],
	watch: {
	},
	mounted(){

	},
}
</script>
<style lang="scss">
.faded-bar{
	background: rgba(0, 0, 0, 0.4);
	text-align: center;
	font-size: 18px;
	line-height: 35px;
	height: 35px;
	width: 100%;
	color: #FFFFFF;
	display: block;
	a{
		font-weight: bold;
		color: #FFFFFF;
	}
}
</style>
