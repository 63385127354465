import moment from 'moment'

export const state = () => ({
	cars: []
})

export const getters = {
	getAuth: (state, getters, rootState) => {
		return rootState;
	}
};


export const mutations = {
	add(state, vin){
		var date = new Date().toISOString().split("T")[0];
		const now = moment(new Date()).local().format("YYYY-MM-DD HH:mm:ss");
		var car = {
			vin: vin,
			date_of: now,
			note: ''
		};
		state.cars.push(car);
	},
	updateNote(state, params){
		var index = -1;
		//if logged in do server side. otherwise do it here...
		state.cars.forEach(function(car, i){
			if(car.vin == params.vin){
				index = i;
			}
		});
		if(index >= 0){
			state.cars[index].note = params.note;
		}
	},
	remove(state, vin){
		var index = -1;
		state.cars.forEach(function(car, i){
			if(car.vin == vin){
				index = i;
			}
		});
		if(index >= 0){
			state.cars.splice(index, 1);
		}
		// state.cars.splice(state.cars.indexOf(vin), 1)
	}
}
