<template>
	<div class="bottom-nav">
		<div class="container">
			<div class="row content ">
				<div class="col-sm-12 col-md-12">
					<nav>
						<ul>
							<li><nuxt-link to="/inventory">Inventory</nuxt-link></li>
							<li><nuxt-link to="/sell-your-tesla">Sell Your Tesla</nuxt-link></li>
							<li><nuxt-link to="/saved">Saved Cars ({{savedCars}})</nuxt-link></li>
							<li><nuxt-link to="/content/about">About</nuxt-link></li>
						</ul>
					</nav>
				</div>
			</div>
			<div class="row">
				<div class="col-sm-12 footer-bar">
					This is an independent organization and is not affiliated with any car companies, including Tesla Motors. All Tesla logos/designs are trademarks of Tesla Motors, Inc.
				</div>
			</div>
		</div>
	</div>
</template>
<script>
//<referral-bar></referral-bar>
import config from '@/config';
import _ from 'lodash';
import ReferralBar from '@/components/ReferralBar'


export default {
	components: {
		referralBar: ReferralBar,
	},
	data() {
		return {
		}
	},
	computed: {
		savedCars(){
			return this.$store.state.saved.cars.length
		}
	},
	methods: {
	},
	props: [
		'vin'
	],
	watch: {
	},
	mounted(){

	},
}
</script>
<style lang="scss">
.footer-bar{
	margin-top: 30px;
	margin-bottom: 0px;
	text-align: left;
	font-size: 14px;
	line-height: 18px;
	width: 100%;
	color: #333333;
	a{
		font-weight: bold;
		color: #FFFFFF;
	}
}
.bottom-nav{
	margin-top: 60px;
	background: rgba(0, 0, 0, 0.1);
	padding: 20px 10px;
	nav {
		display: flex;
		justify-self: center;

	}
	ul {
		list-style-type: none;
		margin-bottom: 0;
		padding-left: 0;
	}
	li {
		display: inline;
	}
	li a {
		padding: 0px 20px 0px 0px;
		font-weight: 600;
		font-size: 18px;
		color: $black;
		display: inline-block;
		text-transform: uppercase;
		&:hover{
			color: $red;
			text-decoration: none;
		}
		&.nuxt-link-active{
			font-weight: 800;
			color: $white;
			&:hover{
				color: $red;
			}
		}
	}
}
</style>
