export const state = () => ({
	touched: false,
	filter: '',
	model: '',
	status: '',
	sold: 2,
	sort: 'date_added',
	vin: '',
	year: '',
	features: []
})

// mounted(){
// 	if(!_.isNil(this.$route.query.model)){
// 		this.filters.model = this.$route.query.model;
// 	}
// 	if(!_.isNil(this.$route.query.status)){
// 		this.filters.status = this.$route.query.status;
// 	}
// 	if(!_.isNil(this.$route.query.filter)){
// 		this.filters.filter = this.$route.query.filter;
// 	}
// 	if(!_.isNil(this.$route.query.sold)){
// 		this.filters.sold = this.$route.query.sold;
// 	}
// 	if(!_.isNil(this.$route.query.vin)){
// 		this.filters.vin = this.$route.query.vin;
// 	}
// 	if(!_.isNil(this.$route.query.features)){
// 		this.filters.features = this.$route.query.features;
// 	}
// 	this.filters.touched = true;
// },


export const mutations = {
	clearFilters(state){
		state.filters = {
			touched: false,
			filter: '',
			model: '',
			status: '',
			sold: 2,
			sort: 'date_added',
			vin: '',
			year: '',
			features: []
		};
	},
	setFilter(state, payload){
		state[payload.filter] = payload.value;
	},
	toggle(state, filter){
		state.filters[filter] = !state.filters[filter];
	}
}
