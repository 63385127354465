export default function ({ $axios, redirect }) {
	$axios.onRequest(config => {
		// const token = localStorage.getItem('token');
		// config.headers.Authorization =  token ? `Bearer ${token}` : '';
		// console.log(config.headers);
		// console.log($auth.strategy.token.get());//
		//this is happening automatically
		return config;
	})
}
